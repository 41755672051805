$(document).ready(function() {

  colorizeHeadlines();

  $('.headline .char').on('mouseenter', function(){

    $char = $(this);

    $('.skill').removeClass('act');
    $showing = $('.skill[data-ref='+$char.data('pos')+']');
    $showing.addClass('act');

    $showing.css({
      'position': 'absolute',
      'visibility': 'visible',
      'left': $char.offset().left,
      'margin-left': ($showing.find('strong').offset().left - $char.width()/2 + $showing.find('strong').width()/2)*-1+'px',
    });
  });
  $('.headline .char').on('mouseleave', function(){
    $('.skill').removeClass('act');
    $('.skill').attr('style','');
    $('.skill[data-ref=0]').addClass('act');
  });
  $('.headline .char').on('mouseleave', function(){
    $('.skill').removeClass('act');
    $('.skill').attr('style','');
    $('.skill[data-ref=0]').addClass('act');
  });

  $('#totop').on('click', function(){
    var body = $("html, body");
    body.stop().animate({scrollTop:0}, 500, 'swing');
  });

  $('.timeline').timeline({
    forceVerticalMode: 700,
    mode: 'horizontal',
    verticalStartPosition: 'left',
    visibleItems: 3
  });

  /*
 * Replace all SVG images with inline SVG
 */
// $('.home img[src$=\".svg\"]').each(function(){
//   var $img = $(this);
//   var imgID = $img.attr('id');
//   var imgClass = $img.attr('class');
//   var imgURL = $img.attr('src');

//   $.get(imgURL, function(data) {
//       // Get the SVG tag, ignore the rest
//       var $svg = $(data).find('svg');

//       // Add replaced image's ID to the new SVG
//       if(typeof imgID !== 'undefined') {
//           $svg = $svg.attr('id', imgID);
//       }
//       // Add replaced image's classes to the new SVG
//       if(typeof imgClass !== 'undefined') {
//           $svg = $svg.attr('class', imgClass+' replaced-svg');
//       }

//       // Remove any invalid XML tags as per http://validator.w3.org
//       $svg = $svg.removeAttr('xmlns:a');

//       // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
//       if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
//           $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
//       }

//       // Replace image with new SVG
//       $img.replaceWith($svg);

//   }, 'xml');

// });


});


function colorizeHeadlines(){
  $('h1, h2, h3, h4').each(function(index, obj){

    if($(this).html().indexOf('<span class="upper">', 0) == -1 && $(this).html().indexOf('<img', 0) == -1){
      if($(this).html() != '&nbsp;' && $(this).html() != '' && $(this).html() != ' ' && $(this).find('img').length == 0){
        if($(this).html().length > 4){
          var length = $(this).html().length;
          var front = $(this).html().substring(0, length-4);
          var center = $(this).html().substring(length-4,length-1);
          var end = $(this).html().substring(length-1, length);
          $(this).html(front+'<span class="upper">'+center+'</span>'+end);
        }
      }

    }

  });
}

